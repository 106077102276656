import { Table } from '@tanstack/react-table'
import { ResaleTable } from '.'
import { utils, writeFile } from 'xlsx'
import { Resale } from '@/types/Resale'
  
export const useResaleSpreadsheets = () => {
    const getTableRows = (table: Table<ResaleTable>): Partial<Resale>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            obj['Nome Rota'] = row.original.DS_ROTA
            obj['Cod. Rota'] = row.original.DD_ROTA
            obj['Tipo'] = row.original.DD_TIPO
            obj['Proprietário'] = row.original.NM_PROPRIETARIO
            obj['Cod. Cliente'] = row.original.DD_CLIENTE
            obj['Loja'] = row.original.DD_LOJA
            obj['Nome Cliente'] = row.original.NM_CLIENTE
            obj['Revenda na Grade'] = row.original.DS_REVENDA_GRADE
            obj['Nome Revenda'] = row.original.NM_REVENDA
            obj['Município'] = row.original.NM_MUNICIPIO
            obj['Endereço'] = row.original.NM_ENDERECO
            obj['Bairro'] = row.original.NM_BAIRRO
            obj['CEP'] = row.original.DD_CEP
            obj['UF'] = row.original.DD_ESTADO
            obj['CNPJ'] = row.original.DD_CNPJ
            obj['Aparecer Limite'] = row.original.DD_APARECER_LIMITE
            obj['Prazo'] = row.original.DD_PRAZO
            obj['Versão'] = row.original.DD_VERSAO
            obj['Limite'] = Number(row.original.DD_LIMITE)
            obj['Tipo Garantia'] = row.original.DD_TIPO_GARANTIA
            obj['Valor'] = row.original.DD_VALOR
            obj['1º Observação'] = row.original.DS_OBSERVACAO1
            obj['2º Obervação'] = row.original.DS_OBSERVACAO2
            obj['Cadatro Limite'] = row.original.DD_CADASTRO_LIMITE 
             
            return obj
        })

    const onDownload = (tableInstance: Table<ResaleTable>) => {
         
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Revendas')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Revenda_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
