import { Table } from '@tanstack/react-table'
import { LimitBankTable } from '.'
import { utils, writeFile } from 'xlsx'
import { LimitBankType } from '@/types/LimitBank'
  
export const useLimiteBancoSpreadsheets = () => {
    const getTableRows = (table: Table<LimitBankTable>): Partial<LimitBankType>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            obj['Empresa(s)'] = row.original.DD_EMPRESA
            obj['Cod. Banco'] = row.original.DD_CODIGO
            obj['Vínculo'] = row.original.DD_VINCULO
            obj['Banco'] = row.original.DS_BANCO
            obj['Valor Taxa'] = row.original.VL_TAXA
            obj['Limite'] = Number(row.original.VL_LIMITE)
            obj['IOF'] = row.original.DD_IOF
            obj['Tipo Instituição'] = row.original.DD_TIPO_INSTITUICAO
            obj['Tipo Operação'] = row.original.DD_TIPO_OPERACAO
            obj['Valor Utilizado'] = Number(row.original.VL_TOTAL_COM_JUROS)
            obj['Saldo'] = Number(row.original.SALDO)
            obj['Valor Vencido'] = Number(row.original.VL_VENCIDO)
            obj['Valor Vencido Hoje'] = Number(row.original.VL_VENCIDO_HOJE)
            return obj
        })

    const onDownload = (tableInstance: Table<LimitBankTable>) => {
         
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Limite')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Limite_Banco_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
