import { Table } from '@tanstack/react-table'
import { BalanceSammaryTable } from '.'
import { utils, writeFile } from 'xlsx'
import { Movimentation } from '@/types/BankStatement'
import { getDateFromId } from '@/utils/date'
  
export const useBalanceSammarySpreadsheets = () => {
    const getTableRows = (table: Table<BalanceSammaryTable>): Partial<Movimentation>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any> 
            
            obj['Data'] = getDateFromId(Number(row.original.DATA))
            obj['Rota'] = row.original.DD_ROTA 
            obj['Descrição Rota'] = row.original.DS_ROTA 
            obj['Proprietário'] = row.original.NM_PROPRIETARIO 
            obj['Histórico'] = row.original.DS_HISTORICO
            obj['Valor Entrada'] = Number(row.original.ENTRADA)
            obj['Valor Saída'] = Number(row.original.SAIDA)
            obj['Valor Saldo'] = Number(row.original.SALDO)

            return obj
        })

    const onDownload = (tableInstance: Table<BalanceSammaryTable>) => {
         
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Resumo do Saldo')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Extrato_Revenda_Resumo_Saldo_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
