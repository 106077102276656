import { Table } from '@tanstack/react-table'
import { HistoryPaymentTable } from '.'
import { utils, writeFile } from 'xlsx'
import { HistoryPayment } from '@/types/BankStatement'
import { getDateFromId } from '@/utils/date'
  
export const useHistoryPaymentSpreadsheets = () => {
    const getTableRows = (table: Table<HistoryPaymentTable>): Partial<HistoryPayment>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any> 

            obj['Rota'] = row.original.DS_ROTA
            obj['Empresa'] = row.original.ABREVIATURA_EMPRESA
            obj['Num. Documento'] = row.original.DD_NUMERO_DOCUMENTO
            obj['Dt. Emissão'] = getDateFromId(Number(row.original.SK_EMISSAO))
            obj['Dt. Vencimento'] = getDateFromId(Number(row.original.SK_VENCIMENTO_REAL))
            obj['Dt. Baixa'] = getDateFromId(Number(row.original.SK_BAIXA))
            obj['Dias Juros'] = row.original.DD_DIAS_JUROS
            obj['Valor Título'] = row.original.VL_NF
            obj['Vl. Pago a Vencer'] = Number(row.original.VL_PAGO_A_VENCER)
            obj['Vl. Pago Vencido'] = Number(row.original.VL_PAGO_VENCIDO)
            obj['Vl. Pago'] = Number(row.original.VL_BAIXADO)
            obj['Vl. Juros Pago'] = Number(row.original.VL_JUROS_BAIXADO)
            obj['Vl. Multa Pago'] = Number(row.original.VL_MULTA_BAIXADA)
            obj['Vl. Original'] = Number(row.original.VL_ORIGINAL)
            obj['ID Carga'] = row.original.DD_IDCARGA
            
            return obj
        })

    const onDownload = (tableInstance: Table<HistoryPaymentTable>) => {
        console.log(tableInstance)
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Historico')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Extrato_Revenda_Historico_Pagamento_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
