import { Table } from '@tanstack/react-table'
import { UnsettledSecuritiesTable } from '.'
import { utils, writeFile } from 'xlsx'
import { UnsettledSecuritiesTotalPosition } from '@/types/UnsettledSecurities'

export const useTotalPositionSpreadsheets = () => {
    const getTableRows = (table: Table<UnsettledSecuritiesTable>): Partial<UnsettledSecuritiesTotalPosition>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any> 

            obj["Revenda"] = row.original.DS_ROTA
            obj["Proprietário"] = row.original.NM_PROPRIETARIO
            obj["Município"] = row.original.NM_MUNICIPIO
            obj["Cod. Cliente"] = row.original.DD_CLIENTE
            obj["Cliente"] = row.original.DS_REVENDA_GRADE
            obj["UF"] = row.original.DD_ESTADO
            obj["Limite Crédito"] = Number(row.original.DD_LIMITE)
            obj["Tipo Garantia"] = row.original.DD_TIPO_GARANTIA
            obj["Valor"] = row.original.DD_VALOR
            obj["1º Observação"] = row.original.DS_OBSERVACAO1
            obj["2º Observação"] = row.original.DS_OBSERVACAO2
            obj["P1 - Total a Vencer"] = row.original.VL_A_VENCER_P1
            obj["P1 - Total Vencido"] = row.original.VL_VENCIDO_P1
            obj["P1 - Vencimento Hoje"] = row.original.VL_VENCIDO_HOJE_P1
            obj["P1 - Total em Aberto"] = row.original.VL_VENCIDO_E_A_VENCER_P1
            obj["P1 Acordo - Total a Vencer"] = row.original.VL_A_VENCER_ACORDO_P1
            obj["P1 Acordo - Total Vencido"] = row.original.VL_VENCIDO_ACORDO_P1
            obj["P1 Acordo - Vencimento Hoje"] = row.original.VL_VENCIDO_ACORDO_HOJE_P1
            obj["P1 Acordo - Total em Aberto"] = row.original.VL_VENCIDO_E_A_VENCER_ACORDO_P1
            obj["P2 - Total a Vencer"] = row.original.VL_A_VENCER_P2
            obj["P2 - Total Vencido"] = row.original.VL_VENCIDO_P2
            obj["P2 - Vencimento Hoje"] = row.original.VL_VENCIDO_HOJE_P2
            obj["P2 - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_P2
            obj["P2 - Total Multa"] = row.original.VL_MULTA_P2
            obj["P2 - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_P2
            obj["P2 Acordo - Total a Vencer"] = row.original.VL_A_VENCER_ACORDO_P2
            obj["P2 Acordo - Total Vencido"] = row.original.VL_VENCIDO_ACORDO_P2
            obj["P2 Acordo - Vencimento Hoje"] = row.original.VL_VENCIDO_ACORDO_HOJE_P2
            obj["P2 Acordo - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_ACORDO_P2
            obj["P2 Acordo - Total Multa"] = row.original.VL_MULTA_ACORDO_P2
            obj["P2 Acordo - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_ACORDO_P2
            obj["P3 - Total a Vencer"] = row.original.VL_A_VENCER_P3
            obj["P3 - Total Vencido"] = row.original.VL_VENCIDO_P3
            obj["P3 - Vencimento Hoje"] = row.original.VL_VENCIDO_HOJE_P3
            obj["P3 - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_P3
            obj["P3 - Total Multa"] = row.original.VL_MULTA_P3
            obj["P3 - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_P3
            obj["P3 Acordo - Total a Vencer"] = row.original.VL_A_VENCER_ACORDO_P3
            obj["P3 Acordo - Total Vencido"] = row.original.VL_VENCIDO_ACORDO_P3
            obj["P3 Acordo - Vencimento Hoje"] = row.original.VL_VENCIDO_ACORDO_HOJE_P3
            obj["P3 Acordo - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_ACORDO_P3
            obj["P3 Acordo - Total Multa"] = row.original.VL_MULTA_ACORDO_P3
            obj["P3 Acordo - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_ACORDO_P3
            obj["Extrato"] = row.original.EXTRATO
            obj["Extrato Menos Vencidos"] = row.original.EXTRATO_MENOS_VENCIDOS
            obj["Extrato Menos Venc. e Á Vencer"] = row.original.EXTRATO_MENOS_VENCIDOS_E_A_VENCER
            obj["Total a Vencer"] = row.original.TOTAL_A_VENCER
            obj["Total Vencido"] = row.original.TOTAL_VENCIDO
            obj["Total Em Aberto"] = row.original.TOTAL_EM_ABERTO
            obj["Acordo - Total a Vencer"] = row.original.TOTAL_A_VENCER_ACORDO
            obj["Acordo - Total Vencido"] = row.original.TOTAL_VENCIDO_ACORDO
            obj["Acordo - Total Em Aberto"] = row.original.TOTAL_EM_ABERTO_ACORDO
            obj["Sit. S/Acordo - Limite Disp."] = row.original.SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL
            obj["Sit. S/Acordo - Á Vencer"] = row.original.SITUACAO_SEM_ACORDO_A_VENCER
            obj["Sit. S/Acordo - Vencido"] = row.original.SITUACAO_SEM_ACORDO_VENCIDO
            obj["Sit. S/Acordo - Total em Aberto"] = row.original.SITUACAO_SEM_ACORDO_TOTAL_EM_ABERTO

            let SITUACAO_SEM_ACORDO_STATUS = "LIBERADO"
            let SITUACAO_SEM_ACORDO_VENCIDO = row.original.SITUACAO_SEM_ACORDO_VENCIDO || 0
            let TOTAL_VENCIDO = row.original.TOTAL_VENCIDO || 0
            let SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL = row.original.SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL || 0
            if(SITUACAO_SEM_ACORDO_VENCIDO > 0 || TOTAL_VENCIDO > 1 || SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL < -1){
                SITUACAO_SEM_ACORDO_STATUS = "BLOQUEADO"
            }
            obj["Sit. S/Acordo - STATUS"] = SITUACAO_SEM_ACORDO_STATUS

            obj["Sit. C/Acordo - Limite Disp."] = row.original.SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL

            let SITUACAO_COM_ACORDO_STATUS = "LIBERADO"
            let SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL = row.original.SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL || 0
            if(TOTAL_VENCIDO > 1 || SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL < -1){
                SITUACAO_COM_ACORDO_STATUS = "BLOQUEADO"
            }
            obj["Sit. C/Acordo - STATUS"] = SITUACAO_COM_ACORDO_STATUS

            let SITUACAO_COM_ACORDO_CHECK_LIMITE = "FALSO" 
            if(TOTAL_VENCIDO == SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL){
                SITUACAO_COM_ACORDO_CHECK_LIMITE = "VERDADEIRO"
            }

            obj["Sit. C/Acordo - Check Limite"] = SITUACAO_COM_ACORDO_CHECK_LIMITE

            let SITUACAO_COM_ACORDO_TEM_SALDO = "NÃO"
            let EXTRATO = row.original.EXTRATO || 0 
            if(EXTRATO != 0){
                SITUACAO_COM_ACORDO_TEM_SALDO = "SIM"
            }
            obj["Sit. C/Acordo - Tem Saldo"] = SITUACAO_COM_ACORDO_TEM_SALDO

            let SITUACAO_COM_ACORDO_INADIMPLENCIA = "NÃO"
            let VL_VENCIDO_P1 = row.original.VL_VENCIDO_P1 || 0 
            let VL_VENCIDO_P2 = row.original.VL_VENCIDO_P2 || 0 
            let VL_JUROS_PELO_PRAZO_P2 = row.original.VL_JUROS_PELO_PRAZO_P2 || 0 
            let VL_MULTA_P2 = row.original.VL_MULTA_P2 || 0 
            let VL_VENCIDO_P3 = row.original.VL_VENCIDO_P3 || 0 
            let VL_JUROS_PELO_PRAZO_P3 = row.original.VL_JUROS_PELO_PRAZO_P3 || 0 
            let VL_MULTA_P3 = row.original.VL_MULTA_P3 || 0 

            if((VL_VENCIDO_P1 + VL_VENCIDO_P2 + VL_JUROS_PELO_PRAZO_P2 + VL_MULTA_P2 + VL_VENCIDO_P3 + VL_JUROS_PELO_PRAZO_P3 +VL_MULTA_P3) > 0){
                SITUACAO_COM_ACORDO_INADIMPLENCIA = "SIM"
            }
            obj["Sit. C/Acordo - Inadimplência"] = SITUACAO_COM_ACORDO_INADIMPLENCIA

            return obj
        })

    const onDownload = (tableInstance: Table<UnsettledSecuritiesTable>) => {
         
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Titulos')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `PosicaoTotal_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
